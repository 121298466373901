* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  color: #4e4646;
}

.beckn-app {
  /* background: url("./assets/experience_bg.svg");
  background-size: cover; */
  background: rgb(243, 243, 243);
  background: linear-gradient(
    90deg,
    rgba(243, 243, 243, 1) 0%,
    rgba(211, 234, 251, 1) 100%
  );
  position: relative;
  min-height: 100vh;
  height: max-content;
  display: grid;
}

.Title {
  font-style: normal;
  color: white;
  font-weight: 275;
}
/* -----------------Card.tsx-------------------------------- */
.image-url-containers img:nth-child(2) {
  display: none;
}

.image-url-containers:hover img:nth-child(1) {
  display: none;
}

.image-url-containers:hover img:nth-child(2) {
  display: block;
}
.image-url-containers img:nth-child(2) {
  display: none;
}
.hover_card:hover .image-url-containers {
  display: flex;
  justify-content: center;
}
.hover_card:hover .image-url-containers img:nth-child(1) {
  display: none;
}
.hover_card:hover .image-url-containers img:nth-child(2) {
  display: block;
}
iframe .header-container .location-btn {
  padding: 10px 10px !important;
}
.ant-tabs-nav {
  margin: 0 auto !important;
  margin-top: 45px !important;
}
.videoScreen {
  margin-top: 45px !important;
}
.tabs-style {
  margin-top: 45px;
  margin-bottom: 60px !important;
}
.osc_tab_change {
  display: flex;
  position: absolute;
  bottom: 26%;
  left: 19%;
}
.osc_tab_change .for_cutm_name {
  margin: 10px 25px;
}

.for_cutm_name,
.for_tetail_store {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 48px;
  border-radius: 26px;
  padding: 14px 15px;
  margin: 10px 0;
  background-color: #fff;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
}
.for_cutm_name img,
.for_tetail_store img {
  width: 24px;
  height: 24px;
  margin: 0px 10px 0px 0px;
}
.osc_tab_change .active {
  background-color: #4398e8;
  color: #fff;
}
.osc_tab_change .opacity {
  opacity: 0.5;
}
.UEI_tab_change {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 23%;
}
.for_sheru_app,
.for_pulse_energy,
.for_turno_app {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 48px;
  border-radius: 26px;
  padding: 14px 15px;
  margin: 20px 20px 20px 0;
  background-color: #fff;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
}
.UEI_tab_change .active {
  background-color: #4498e8;
  color: #fff;
}

.pulse_energy {
  height: 686px;
}
.QR_custom_pulse_energy {
  padding: 38px;
}
.QR_custom_pulse_energy canvas {
  height: 100% !important;
  width: 100% !important;
}
.PCM_card .PCM_card_btn {
  margin: 20px auto;
}
/* ----------------------------Media Query---------------------- */
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .headLogo-image {
    margin-top: 20px !important;
  }
  .home-buttom-nevigate {
    top: 20px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1224px) {
  .page-content {
    height: 100%;
    width: 100%;
  }
  .card-head {
    width: 30%;
    margin: 15px;
  }
  .image-url-containers {
    margin: unset !important;
    width: 100%;
  }
  .firstImage {
    width: 100%;
  }
  .secondImage {
    width: 100%;
  }
  .child-container {
    height: 100%;
  }
  .videoScreen {
    width: 900px !important;
    height: 550px !important;
    margin-top: 43px !important;
  }
}

@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: portrait) {
  .videoScreen {
    width: 725px !important;
    height: 408px !important;
  }
  .exit-icon {
    bottom: 160px !important;
    right: 25px !important;
  }
}
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape) {
  .tab-wrappper-content {
    width: 75% !important;
  }
  .card-container {
    width: 1000px !important;
  }
}
@media screen and (min-width: 1225px) and (max-width: 1800px) {
  .beckn-app {
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;
    height: max-content;
  }
}

.card-sub-containers li:hover {
  scale: 1.03;
}
.osc_tab_change.dsnp_url_tab {
  bottom: 32%;
  left: 0;
}
.dsnp_retail_store,
.for_dsnp_app {
  width: 168px;
}
.dsnp_url_tab .for_dsnp_app {
  margin: 10px auto;
}
.dsnp_url_tab .dsnp_retail_store {
  margin: 10px 25px 0 0;
}
.dsnp_url_tab .active_dsnp {
  background-color: #c2621a;
  color: #fff;
}
.cust_modal .ant-modal-content {
  padding-bottom: 20px !important;
}
.osc_tab_changeFa {
  bottom: 23% !important;
}
.ant-tabs-nav-operations {
  display: none !important;
}
.UEI_NewFlow_container {
  width: 1440px;
  margin: 0 auto;
}
.QR-wrapper.regenUEI_flow_QRcode_new_flow {
  width: 306px;
  height: 381px;
}
.UEI_newFlow {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.UEI_newFlow .video-container,
.UEI_newFlow .video-container iframe,
.regenUEI_flow_QRcode_new_flow_wrrp .video-container,
.regenUEI_flow_QRcode_new_flow_wrrp .video-container iframe {
  width: 100%;
  height: 488px;
}
.UEI_newFlow .video-container iframe,
.regenUEI_flow_QRcode_new_flow_wrrp .video-container iframe {
  /* width: 778px; */
  height: 488px;
  width: 100%;
}
.UEI_text {
  font-size: 48px;
  font-weight: 600;
  color: #4e7796;
  margin-right: -140px;
  padding-left: 114px;
}
.regenUEI_flow_QRcode_new_flow_wrrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.UEI_NewFlow_container_innr {
  margin-top: 40px;
  margin-left: 80px;
  margin-right: 80px;
}
.UEI_QrFlow_container_innr {
  /* width: 1340px; */
}
.UEI_newFlow_btn {
  margin-bottom: 50px !important;
  margin-left: -20px !important;
  margin-top: 30px !important;
}
.UEI_newFlow_btn .UEI_new_btn {
  font-size: 20px;
  font-weight: 400;
  width: 177px;
  height: 48px;
  margin: 0 16px;
}
.beckn_logo_UEI {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 0px;
}
.UEI_footer_new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  padding-top: 60px;
}
.UEI_footer_icons {
  display: flex;
  align-items: center;
  color: #000;
}
.UEI_footer_icons img {
  padding-right: 8px;
}
.UEI_credits {
}
.UEI_flow {
  position: absolute;
  bottom: 6px;
  left: 90px;
}
.UEI_flow > button {
  margin-top: unset !important;
}
.UEI_NewFlow_container_innr {
  position: relative;
}

@media screen and (min-height: 1000px) and (max-height: 1200px) {
  .UEI_NewFlow_container_innr {
    margin-top: 80px;
  }
}
@media screen and (max-height: 880px) {
  .UEI_NewFlow_container_innr {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1310px) and (max-width: 1439px) {
  .UEI_NewFlow_container_innr {
    margin-left: 40px;
    margin-right: 40px;
  }
  .UEI_NewFlow_container {
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1310px) and (max-width: 1439px) {
  .UEI_NewFlow_container_innr {
    margin-left: 40px;
    margin-right: 40px;
  }

  .UEI_NewFlow_container {
    width: 100%;
    margin: 0 auto;
  }
}
/* @media screen and (min-width: 1224px) and (max-width: 1310px) {
  .UEI_NewFlow_container_innr {
    margin-left: 5px;
    margin-right: 5px;
  }
} */
/* @media screen and (min-width: 1600px) and (max-width: 2000px) {
  .UEI_NewFlow_container {
    width: 1600px;
  }
  .UEI_NewFlow_container_innr {
    margin-left: 120px;
    margin-right: 120px;
  }
} */
.UEI_NewFlow_container {
  position: relative;
}
.UEI_NewFlow_container .exit-icon {
  position: absolute;
  right: -60px;
}
.slick-slider {
  user-select: unset !important;
}
.climate-resilience-tabs-btn {
  position: fixed;
  bottom: 160px;
  right: 46px;
  flex-direction: column;
  display: flex;
  gap: 12px;
  background-color: #fff;
  padding: 10px;
  border-radius: 50px;
  transition: filter 0.3s ease;
}
.climate-resilience-tabs-btn img {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s, 0.3s;
  filter: grayscale(100%);
}
.climate-resilience-tabs-btn img:hover {
  transform: scale(1.1);
}
.hideCursor {
  pointer-events: none;
  filter: grayscale(0) !important;
}
@media screen and (min-width: 1025px) and (max-width: 1471px) {
  .climate-resilience-tabs-btn {
    position: fixed;
    bottom: 100px;
    right: 40px;
    border-radius: 50px;
  }
  .climate-resilience-tabs-btn img {
    width: 40px;
    height: 40px;
  }
}
.white-icon svg {
  stroke: #fff;
  font-size: 26px;
  margin-right: 10px;
}
.black-icon svg {
  font-size: 26px;
  margin-right: 10px;
}
.white-icon svg path {
  fill: #fff;
}
.open-spark-tab-change {
  display: flex;
  position: absolute;
  /* bottom: 25%;
  left: 19%; */
  bottom: 20%;
  left: 0;
}
.open-spark-footer-logo {
  position: absolute;
  bottom: -15px;
}
.open-spark-btn {
  margin: 10px 20px !important;
  margin-left: 0 !important;
  padding: 10px !important;
  width: 140px !important;
}
.DSEP-intruction.spark-instruction {
  position: relative;
  width: 100%;
}

.DSEP-intruction.spark-instruction .ant-btn-primary {
  background: #ffffff;
  color: #000000;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.DSEP-intruction.spark-instruction .ant-btn-primary:hover {
  background: #4498e8;
  color: #ffffff;
}
.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.spinner::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #4398e8;
  animation: prixClipFix 3s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
.open-spark-app .text_wrapper_modal {
  min-height: 390px;
}
