.card-with-image {
  width: 308px;
  height: 392px;
  background: #f6fbfd;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 35px;
  margin: 25px 10px;
}
.card-with-image img {
  width: 88%;
}
.Beckn-image {
  height: 50px;
  width: 161px;
}
.headLogo-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: unset !important;
  width: 1280px;
  margin: 100px auto;
}
.card-sub-containers {
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  width: 94%;
  margin-bottom: 20px;
}
.card-containers {
  display: flex;
  justify-content: center;
}
.first-image-card {
  margin-top: 22px;
}
.card-title-image {
  font-weight: 400;
  font-size: 23px;
  line-height: 30px;
  margin-top: 12px;
  color: #4e4646;
  text-transform: lowercase;
}
.font-color {
  color: #4e4646;
}
.second-image-card {
  margin-top: 22px;
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.page-content-title {
  font-weight: 400;
  font-size: 64px;
  line-height: 96px;
  color: #4e4646;
}
.home-buttom-nevigate {
  border-radius: 30px;
  font-size: 28px;
  font-weight: 400;
  background: #e9ebed;
  width: 150px;
}
.card-with-image {
  cursor: pointer;
}
/* -------fade-in---------------- */

.fadeinout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.animates {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.fives {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.fadeIns {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  animation: fadeinout 4s;
}
.home-card-container {
  overflow: auto;
  height: 77vh;
  margin: 35px auto;
  margin-bottom: unset;
}

.home-card-container::-webkit-scrollbar {
  width: 13px;
}

.home-card-container::-webkit-scrollbar-thumb {
  background: url("/public/assets/scrollBar.svg");
  border-radius: 10px;
  background-size: 0 0;
  background-repeat: no-repeat;
}
.home-card-container:hover::-webkit-scrollbar-thumb {
  background-size: 100% 88%;
}
.climate-gesilience-header-text {
  font-size: 32px;
  font-weight: 600;
  color: #4e7796;
}
.climate-gesilience-sub-text {
  color: #7b7b7b;
  padding-top: 10px;
}
.cliement-resilience.home-card-container {
  overflow: hidden;
  height: unset;
}
.cliement-resilience.home-card-container iframe {
  width: 100%;
  border: 1px solid rgb(138, 194, 235);
  border-radius: 5px;
  height: 554px;
}
.cliement-resilience.home-card-container .video-container {
  padding: 0rem 0rem 1rem 0;
}
.cliement-resilience-video-section-header {
  display: flex;
  margin: 1rem 1rem 1rem 0;
}
.cliement-resilience-video-section-header p {
  color: #4e7796;
  font-size: 24px;
  padding-left: 10px;
}
.cliement-resilience.home-card-container .card-with-image {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
}
@keyframes main-container {
  100% {
    opacity: 0;
  }
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
  .headLogo-image {
    width: 90%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1471px) {
  .fadeIns {
    margin-top: 50px;
  }
  .headLogo-image {
    width: 90%;
  }
}
